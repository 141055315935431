import { View } from 'react-native'

export default function({ children }) {
    return (
        <View
            style={{
                marginTop: 5,
                marginBottom: 5,
                width: '90%',
                maxWidth: 400
            }}
        >
            {children}
        </View>
    )
}