import { ScrollView } from 'react-native'

export default function({ children }) {
    return (
        <ScrollView
            bounces={false}
            contentContainerStyle={{
                padding: 10,
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#efefef'
            }}
        >
            {children}
        </ScrollView>
    )
}