import { useState, useContext } from 'react'
import {
    View,
    Text,
    Image,
    SafeAreaView,
    Switch,
    TouchableOpacity,
} from 'react-native'
import Theme from '../Theme'
import { AuthContext } from '../context'
import marsAgencyLogo from '../../assets/logo.png'
import Anchor from '../component/Anchor'
import Button from '../component/Button'
import Modal from '../component/Modal'
import ScreenContainer from '../component/ScreenContainer'
import TextInput from '../component/TextInput'
import TextLabel from '../component/TextLabel'

export default function LoginScreen() {
    const { requestCode, signIn } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [hasConsent, setHasConsent] = useState(false)

    const requestOtp = () => {
        setIsLoading(true)

        requestCode(email)
        .then(() => {
            setIsLoading(false)
            setIsOtpSent(true)
        })
        .catch(error => {
            setIsLoading(false)
            setIsError(true)
        })
    }

    const validateOtp = () => {
        setIsLoading(true)

        signIn(email, otp)
        .catch(error => {
            setIsLoading(false)
            setIsError(true)
        })
    }

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#efefef" }}>
            <ScreenContainer>
                <Image source={marsAgencyLogo} style={{ width: 200, height: 26, marginBottom: 40 }} />

                { isOtpSent ?
                    <View style={{ alignItems: 'center' }}>
                        <TextLabel>
                            Please check your email and enter the code to login.
                        </TextLabel>

                        <TextInput
                            value={otp}
                            onChangeText={setOtp}
                            autoFocus={true}
                            keyboardType="numeric"
                            style={{
                                fontSize: 24,
                                width: 160,
                                height: 50,
                                textAlign: 'center'
                            }}
                        />

                        <Button onPress={validateOtp} isLoading={isLoading}>
                            Login
                        </Button>

                        <TouchableOpacity
                            onPress={() => setIsOtpSent(false)}
                            style={{ marginTop: 30 }}
                        >
                            <Text 
                                style={{
                                    fontFamily: 'NunitoSans_400Regular',
                                    fontSize: 14,
                                    marginTop: 30,
                                    color: '#444'
                                }}
                            >
                                Request new code
                            </Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <View style={{ alignItems: 'center' }}>
                        <TextLabel>
                            Please enter your email address to begin.
                        </TextLabel>

                        <TextInput
                            value={email}
                            onChangeText={setEmail}
                            autoFocus={true}
                            autoCompleteType="email"
                            keyboardType="email-address"
                            textContentType="emailAddress"
                        />

                        <View style={{ flexDirection: 'row', marginTop: 30 }}>
                            <Switch
                                trackColor={{ false: '#999', true: Theme.colors.primary }}
                                thumbColor="#ffffff"
                                ios_backgroundColor="#999"
                                onValueChange={() => setHasConsent(previousState => !previousState)}
                                value={hasConsent}
                            />

                            <TextLabel style={{ maxWidth: 240, fontSize: 14, textAlign: 'left', marginLeft: 10 }}>
                                By signing up you agree to our <Anchor href="https://commercescope.app/privacy-policy/">privacy policy</Anchor> and <Anchor href="https://commercescope.app/terms-and-conditions/">terms and conditions</Anchor>.
                            </TextLabel>
                        </View>

                        <Button onPress={requestOtp} isLoading={isLoading} isDisabled={!hasConsent}>
                            Next
                        </Button>
                    </View>
                }

                <Modal visible={isError}>
                    <TextLabel>There was an unexpected error.</TextLabel>

                    <Button onPress={() => setIsError(false)}>
                        Back
                    </Button>
                </Modal>
            </ScreenContainer>
        </SafeAreaView>
    )
}