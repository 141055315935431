import { Text } from 'react-native'
import * as Linking from 'expo-linking'
import Theme from '../Theme'

export default function(props) {

    const handlePress = () => {
        Linking.openURL(props.href)
    }

    return (
        <Text style={{ color: Theme.colors.primary, textDecorationLine: 'underline' }} onPress={handlePress}>
            {props.children}
        </Text>
    )

}