import { useState, createContext } from 'react'

export const Context = createContext()

export const Provider = props => {
    const { children } = props

    const [image, setImage] = useState(null)

    const feedContext = {
        image,
        setImage
    }

    return <Context.Provider value={feedContext}>{children}</Context.Provider>
}

export const { Consumer } = Context