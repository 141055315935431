import { Image } from 'react-native'
import logoSource from '../../assets/commercescope.png'

export default function() {
    return (
        <Image
            source={logoSource}
            style={{
                width: 200,
                height: 25,
                resizeMode: 'contain' 
            }}
        />
    )
}