import { useContext, useState } from 'react'
import Constants from 'expo-constants'
import {
    ActivityIndicator,
    View,
    Image
} from 'react-native'
import { FeedContext } from '../context'

export default function PhotoScreen() {
    const { image } = useContext(FeedContext)
    const [isLoading, setIsLoading] = useState(true)

    return (
        <View style={{ flexGrow: 1, justifyContent: 'center', backgroundColor: 'black' }}>
            <Image
                source={{ uri: `${Constants.manifest.extra.appDomain}/${image.path}` }}
                style={{ width: '100%', height: '100%', resizeMode: 'contain' }}
                onLoadEnd={() => setIsLoading(false)}
            />

            { isLoading ? <ActivityIndicator size="large" color="#bbb" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }} /> : null }
        </View>
    )
}
