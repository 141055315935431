import { createStackNavigator } from '@react-navigation/stack'
import BottomTabs from './BottomTabs'
import SettingsScreen from '../screen/SettingsScreen'

const RootStack = createStackNavigator()

export default function() {
    return (
        <RootStack.Navigator>
            <RootStack.Screen
                name="Root"
                component={BottomTabs}
                options={{
                    headerShown: false
                }}
            />
            <RootStack.Screen
                name="Settings"
                component={SettingsScreen}
                options={{
                    headerTitleAlign: 'center',
                    headerBackTitle: ''
                }}
            />
        </RootStack.Navigator>
    )
}