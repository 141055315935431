import {
    StyleSheet,
    Platform
} from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import RNPickerSelect from 'react-native-picker-select'

export default function(props) {
    const pickerProps = {
        style: styles,
        Icon: () => {
            return Platform.OS === 'ios' ? <Ionicons name="chevron-down" size={24} color="#666" /> : null
        },
        ...props
    }

    return (
        <RNPickerSelect
            {...pickerProps}
        />
    )
}                

const styles = StyleSheet.create({
    inputIOS: {
        fontFamily: 'NunitoSans_400Regular',
        fontSize: 16,
        paddingVertical: 12,
        paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: '#bbb',
        borderRadius: 4,
        backgroundColor: 'white',
        color: '#444',
        paddingRight: 30
    },
    inputAndroid: {
        fontFamily: 'NunitoSans_400Regular',
        fontSize: 16,
        paddingHorizontal: 10,
        paddingVertical: 4,
        borderWidth: 1,
        borderColor: '#bbb',
        borderRadius: 4,
        backgroundColor: 'white',
        color: '#444',
        paddingRight: 20
    },
    inputWeb: {
        fontFamily: 'NunitoSans_400Regular',
        fontSize: 16,
        padding: 8,
        borderRadius: 4,
        borderColor: '#bbb',
        backgroundColor: 'white',
        color: '#444'
    },
    iconContainer: {
        top: 10,
        right: 10
    }
})