import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import Ionicons from '@expo/vector-icons/Ionicons'
import Theme from '../Theme'
import CaptureStack from './CaptureStack'
import FeedStack from './FeedStack'

const ICONS = {
    'Capture': 'camera',
    'Feed': 'grid'
}

const Tabs = createBottomTabNavigator()

export default function() {
    return (
        <Tabs.Navigator
            screenOptions={({ route }) => ({
                tabBarActiveTintColor: Theme.colors.primary,
                tabBarInactiveTintColor: 'gray',
                tabBarIcon: ({ color }) => {
                    const iconName = ICONS[route.name] || ''
                    return <Ionicons name={iconName} size={30} color={color} />
                }
            })
        }>
            <Tabs.Screen name="Capture" component={CaptureStack} />
            <Tabs.Screen name="Feed" component={FeedStack} />
        </Tabs.Navigator>
    )
}