import {
    StyleSheet,
    Text
} from 'react-native'

export default function({ children, ...props}) {
    const textProps = {
        ...props,
        style: [
            styles.default,
            props.style || {}
        ]
    }

    return (
        <Text {...textProps}>{children}</Text>
    )
}

const styles = StyleSheet.create({
    default: {
        fontFamily: 'NunitoSans_400Regular',
        fontSize: 16,
        marginBottom: 15,
        textAlign: 'center',
        color: '#444'
    }
})