import Constants from 'expo-constants'
import { createContext, useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const Context = createContext()

export const Provider = props => {
    const { children } = props

    const [userToken, setUserToken] = useState(null)

    // Request OTP
    const requestCode = async email => {
        let response = await fetch(`${Constants.manifest.extra.appDomain}/login/request-code`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                email: email
            })
        })

        let json = await response.json()

        if (!json.success) {
            throw new Error('Request error.')
        }
    }

    // Sign in
    const signIn = async (email, otp) => {
        let response = await fetch(`${Constants.manifest.extra.appDomain}/login/validate-code`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                email: email,
                otp: otp
            })
        })

        let json = await response.json()

        if (json.error) {
            throw new Error('Request error.')
        }

        await AsyncStorage.setItem('userToken', json.token)

        setUserToken(json.token)
    }

    // Sign out
    const signOut = async () => {
        await AsyncStorage.removeItem('userToken')

        setUserToken(null)
    }

    const authContext = {
        userToken,
        setUserToken,
        requestCode,
        signIn,
        signOut
    }

    return <Context.Provider value={authContext}>{children}</Context.Provider>
}

export const { Consumer } = Context