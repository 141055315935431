import { Modal, View } from 'react-native'
import { BlurView } from 'expo-blur'

function ModalOverlay({ children }) {
    return (
        <BlurView intensity={20} style={{ flex: 1 }}>
            <View
                style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, .7)'
                }}
            >
                {children}
            </View>
        </BlurView>
    )
}

function ModalBody({ children }) {
    return (
        <View
            style={{
                padding: 20,
                width: '80%',
                maxWidth: 300,
                borderRadius: 10,
                backgroundColor: 'white',
                color: '#444'
            }}
        >
            {children}
        </View>
    )
}

export default function(props) {
    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={props.visible}
        >
            <ModalOverlay>
                <ModalBody>
                    {props.children}
                </ModalBody>
            </ModalOverlay>
        </Modal>
    )
}