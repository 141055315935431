import { useContext } from 'react'
import { StyleSheet } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import { ImageContext } from '../context'
import Button from '../component/Button'
import ScreenContainer from '../component/ScreenContainer'

export default function HomeScreen({ navigation }) {
    const { setImage } = useContext(ImageContext)

    // Open camera
    const openCamera = async () => {
        const permission = await ImagePicker.requestCameraPermissionsAsync()

        if (permission.granted === false) {
            return
        }

        const result = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            base64: true
        })

        if (result.cancelled === true) {
            return
        }

        setImage({ uri: result.uri, base64: result.base64 })

        // Show upload screen
        navigation.navigate('Upload')
    }

    // Open image picker
    const openImagePicker = async () => {
        let permission = await ImagePicker.requestMediaLibraryPermissionsAsync()

        if (permission.granted === false) {
            return
        }

        let result = await ImagePicker.launchImageLibraryAsync({ base64: true })

        if (result.cancelled === true) {
            return
        }

        setImage({ uri: result.uri, base64: result.base64 })

        // Show upload screen
        navigation.navigate('Upload')
    }

    return (
        <ScreenContainer>
            <Button onPress={openCamera} style={styles.button} textStyle={styles.buttonText}>
                Take a photo
            </Button>

            <Button onPress={openImagePicker} style={styles.button} textStyle={styles.buttonText}>
                Pick a photo
            </Button>
        </ScreenContainer>
    )
}

const styles = StyleSheet.create({
    button: {
        width: 200,
        height: 54,
        borderRadius: 8
    },
    buttonText: {
        fontSize: 24,
        fontFamily: 'NunitoSans_600SemiBold'
    }
})