import { useState, useEffect, useContext } from 'react'
import Constants from 'expo-constants'
import {
    Image,
    Platform
} from 'react-native'
import * as Location from 'expo-location'
import {
    AuthContext,
    ImageContext
} from '../context'
import Button from '../component/Button'
import InputContainer from '../component/InputContainer'
import LoadingScreen from '../component/LoadingScreen'
import Modal from '../component/Modal'
import PickerInput from '../component/PickerInput'
import ScreenContainer from '../component/ScreenContainer'
import TextInput from '../component/TextInput'
import TextLabel from '../component/TextLabel'

export default function UploadScreen({ navigation }) {
    const { image, defaultCountry, setDefaultCountry, metadataOptions, setMetadataOptions, location, setLocation } = useContext(ImageContext)
    const { userToken } = useContext(AuthContext)

    const [isReady, setIsReady] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [country, setCountry] = useState(defaultCountry)
    const [retailer, setRetailer] = useState()
    const [category, setCategory] = useState()
    const [narrative, setNarrative] = useState()
    const [isNarrativeVisible, setIsNarrativeVisible] = useState(false)

    Location.setGoogleApiKey(Constants.manifest.extra.googleMapsApiKey)

    const fetchMetadataOptions = async () => {
        try {
            const response = await fetch(`${Constants.manifest.extra.appDomain}/api/metadata`, {
                headers: {
                    'Accept': 'application/json',
                    'X-Auth-Token': userToken
                }
            })

            return response.json()
        } catch (error) {

        }
    }

    const fetchUserLocation = async () => {
        let { status } = await Location.requestForegroundPermissionsAsync()

        if (status !== 'granted') {
            return {
                coords: null,
                countryCode: null
            }
        }

        const { coords } = await Location.getCurrentPositionAsync({})
        const geocode = await Location.reverseGeocodeAsync({ latitude: coords.latitude, longitude: coords.longitude })

        // Locate country or region code
        let countryCode = 'GB'

        if (geocode.length > 0 && metadataOptions) {
            const { isoCountryCode } = geocode[0]

            if (metadataOptions.regions) {
                for (const [key, value] of Object.entries(metadataOptions.regions)) {
                    if (value.includes(isoCountryCode)) {
                        countryCode = key
                        break
                    }
                }
            }

            if (metadataOptions.countries) {
                const countries = metadataOptions.countries.map(({ code }) => code)

                if (countries.includes(isoCountryCode)) {
                    countryCode = isoCountryCode
                }
            }
        }

        return {
            coords,
            countryCode
        }
    }

    useEffect(() => {
        (async () => {
            const metadata = await fetchMetadataOptions()
            setMetadataOptions(metadata)

            const { coords, countryCode } = await fetchUserLocation()
            setLocation(coords)
            setDefaultCountry(countryCode)

            setIsReady(true)
        })()
    }, [])

    if (!isReady) {
        return <LoadingScreen />
    }

    // Upload image
    const uploadImage = async () => {
        setIsLoading(true)

        try {
            const appVersion = `${Platform.OS}-${Constants.manifest.version}`

            let response = await fetch(`${Constants.manifest.extra.appDomain}/api/media`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Auth-Token': userToken
                },
                body: JSON.stringify({
                    appVersion,
                    base64Image: image.base64,
                    meta: {
                        country,
                        retailer,
                        category
                    },
                    location: {
                        latitude: location?.coords?.latitude,
                        longitude: location?.coords?.longitude
                    },
                    narrative
                })
            })
            
            let json = await response.json()
            setIsLoading(false)

            if (!json.success) {
                throw new Error('Upload error.')
            }

            setIsSuccess(true)
        } catch (error) {
            setIsLoading(false)
            setIsError(true)
        }
    }

    return (
        <ScreenContainer>
            <Image source={{ uri: image.uri }} style={{ marginBottom: 10, width: '100%', height: 240, resizeMode: 'contain' }} />

            <InputContainer>
                <PickerInput
                    value={country}
                    onValueChange={value => setCountry(value)}
                    placeholder={{ label: 'Select a country', value: '' }}
                    items={metadataOptions.countries.map(country => {
                        return {
                            label: country.name,
                            value: country.code
                        }
                    })}
                />
            </InputContainer>

            <InputContainer>
                <PickerInput
                    onValueChange={value => setRetailer(value)}
                    placeholder={{ label: 'Select a retailer', value: '' }}
                    items={metadataOptions.retailers[country].map(retailer => {
                        return {
                            label: retailer,
                            value: retailer
                        }
                    })}
                />
            </InputContainer>

            <InputContainer>
                <PickerInput
                    onValueChange={value => setCategory(value)}
                    placeholder={{ label: 'Select a category', value: '' }}
                    items={metadataOptions.categories.map(category => {
                        return {
                            label: category,
                            value: category
                        }
                    })}
                />
            </InputContainer>

            <Button onPress={() => setIsNarrativeVisible(true) } style={{ backgroundColor: 'transparent' }} textStyle={{ color: '#666' }}>
                Add narrative
            </Button>

            <Modal visible={isNarrativeVisible}>
                <TextLabel>Add a short description to provide context to your image.</TextLabel>
                <TextInput
                    value={narrative}
                    onChangeText={setNarrative}
                    multiline={true}
                    numberOfLines={6}
                    textAlignVertical="top"
                    style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                        width: '100%',
                        height: 120
                    }}
                />

                <Button onPress={() => setIsNarrativeVisible(false)}>
                    Confirm
                </Button>
            </Modal>

            <Button isLoading={isLoading} onPress={uploadImage} style={{ marginVertical: 20 }}>
                Upload image
            </Button>

            <Modal visible={isSuccess}>
                <TextLabel>Thank you for your submission.</TextLabel>

                <Button onPress={() => navigation.navigate('Home')}>
                    Okay
                </Button>
            </Modal>

            <Modal visible={isError}>
                <TextLabel>There was an error uploading your submission.</TextLabel>

                <Button onPress={() => setIsError(false)}>
                    Back
                </Button>
            </Modal>
        </ScreenContainer>
    )
}