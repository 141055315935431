import {
    ActivityIndicator,
    StyleSheet,
    Text,
    TouchableOpacity,
    View
} from 'react-native'
import Theme from '../Theme'

export default function({ children, textStyle, ...props }) {
    const buttonProps = {
        ...props,
        style: [
            styles.default,
            props.style || {}
        ]
    }

    const textProps = {
        style: [
            styles.text,
            textStyle || {}
        ]
    }

    if (props.isLoading) {
        return (
            <View style={buttonProps.style}>
                <ActivityIndicator color="#ffffff" />
            </View>
        )
    }

    if (props.isDisabled) {
        return (
            <View style={[buttonProps.style, { backgroundColor: '#999' }]}>
                <Text style={textProps.style}>{children}</Text>
            </View>
        )
    }

    return (
        <TouchableOpacity onPress={buttonProps.onPress} style={buttonProps.style}>
            <Text style={textProps.style}>{children}</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    default: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Theme.colors.primary,
        color: 'white',
        minWidth: 160,
        height: 40,
        marginVertical: 5,
        paddingHorizontal: 20,
        borderRadius: 6
    },
    text: {
        fontFamily: 'NunitoSans_700Bold',
        fontSize: 18,
        color: 'white',
        lineHeight: 40
    }
})