import { useState, useContext } from 'react'
import AppLoading from 'expo-app-loading'
import { StatusBar } from 'expo-status-bar'
import { NavigationContainer } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
    useFonts,
    NunitoSans_400Regular,
    NunitoSans_600SemiBold,
    NunitoSans_700Bold
} from '@expo-google-fonts/nunito-sans'
import {
    AuthContext,
    AuthContextProvider,
    ImageContextProvider
} from './context'
import RootStack from './navigation/RootStack'
import LoginScreen from './screen/LoginScreen'
import Theme from './Theme'

function AppShell() {
    const [isReady, setIsReady] = useState(false)
    const { userToken, setUserToken } = useContext(AuthContext)

    const initialLoad = async () => {
        const token = await AsyncStorage.getItem('userToken')
        setUserToken(token)
    }

    // Load fonts
    const [fontsLoaded] = useFonts({
        NunitoSans_400Regular,
        NunitoSans_600SemiBold,
        NunitoSans_700Bold,
    })

    if (!fontsLoaded || !isReady) {
        return (
            <AppLoading
                startAsync={initialLoad}
                onFinish={() => setIsReady(true)}
                onError={console.warn}
            />
        )
    }

    if (!userToken) {
        return <LoginScreen />
    }

    return (
        <NavigationContainer theme={Theme}>
            <RootStack />
        </NavigationContainer>
    )
}

export default function() {
    return (
        <AuthContextProvider>
            <ImageContextProvider>
                <StatusBar style="dark" />
                <AppShell />
            </ImageContextProvider>
        </AuthContextProvider>
    )
}