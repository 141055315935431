import { useState, useEffect, useContext } from 'react'
import Constants from 'expo-constants'
import {
    FlatList,
    Image,
    TouchableOpacity,
    View
} from 'react-native'
import {
    AuthContext,
    FeedContext
} from '../context'
import Button from '../component/Button'
import LoadingScreen from '../component/LoadingScreen'
import TextLabel from '../component/TextLabel'

export default function FeedScreen({ navigation }) {
    const [isReady, setIsReady] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [photos, setPhotos] = useState(null)
    const { setImage } = useContext(FeedContext)
    const { userToken } = useContext(AuthContext)

    const fetchPhotos = async () => {
        try {
            const response = await fetch(`${Constants.manifest.extra.appDomain}/api/media/me`, {
                headers: {
                    'Accept': 'application/json',
                    'X-Auth-Token': userToken
                }
            })

            const json = await response.json()
            const { items } = json

            setPhotos(items)
        } catch (err) {
            
        }
    }

    const refreshPhotos = async () => {
        setIsRefreshing(true)

        await fetchPhotos()

        setIsRefreshing(false)
    }

    useEffect(() => {
        (async() => {
            await fetchPhotos()
            setIsReady(true)
        })()
    }, [])

    const showPhoto = item => {
        setImage(item)
        navigation.navigate('Photo')
    }

    const renderPhoto = ({ item }) => (
        <TouchableOpacity onPress={() => showPhoto(item)} style={{ aspectRatio: 1, flex: 1 / 3, margin: 1, backgroundColor: '#ddd' }}>
            <Image source={{ uri: `${Constants.manifest.extra.appDomain}/thumb/${item.path}?w=300` }} style={{ width: '100%', height: '100%', resizeMode: 'cover' }} />
        </TouchableOpacity>
    )

    if (!isReady) {
        return <LoadingScreen />
    }

    return (
        <FlatList
            data={photos}
            renderItem={renderPhoto}
            onRefresh={refreshPhotos}
            refreshing={isRefreshing}
            numColumns={3}
            keyExtractor={(item, index) => index}
            ListEmptyComponent={() => (
                <View style={{ padding: 40, alignItems: 'center' }}>
                    <TextLabel style={{ width: 180 }}>You haven't submitted anything yet.</TextLabel>

                    <Button onPress={refreshPhotos} isLoading={isRefreshing}>
                        Reload?
                    </Button>
                </View>
            )}
        />
    )
}
