import { useState, createContext } from 'react'

export const Context = createContext()

export const Provider = props => {
    const { children } = props

    const [image, setImage] = useState(null)
    const [location, setLocation] = useState(null)
    const [defaultCountry, setDefaultCountry] = useState('GB')
    const [metadataOptions, setMetadataOptions] = useState(null)

    const imageContext = {
        image,
        setImage,
        location,
        setLocation,
        defaultCountry,
        setDefaultCountry,
        metadataOptions,
        setMetadataOptions
    }

    return <Context.Provider value={imageContext}>{children}</Context.Provider>
}

export const { Consumer } = Context