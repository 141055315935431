import {
    TouchableOpacity,
    View
} from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import Ionicons from '@expo/vector-icons/Ionicons'
import HeaderLogo from '../component/HeaderLogo'
import HomeScreen from '../screen/HomeScreen'
import UploadScreen from '../screen/UploadScreen'

const CaptureStack = createStackNavigator()

export default function({ navigation }) {
    return (
        <CaptureStack.Navigator
            screenOptions={{
                headerTitle: () => <HeaderLogo />,
                headerTitleAlign: 'center'
            }}>
            <CaptureStack.Screen name="Home" component={HomeScreen}
                options={{
                    headerRight: () => (
                        <View style={{ paddingRight: 10 }}>
                            <TouchableOpacity onPress={() => navigation.navigate('Settings')}>
                                <Ionicons name="settings-sharp" size={24} color="#999" />
                            </TouchableOpacity>
                        </View>
                    )
                }}
            />
            <CaptureStack.Screen name="Upload" component={UploadScreen} options={{ headerBackTitle: '' }} />
        </CaptureStack.Navigator>
    )
}