import {
    StyleSheet,
    TextInput
} from 'react-native'

export default function(props) {
    const inputProps = {
        ...props,
        style: [
            styles.default,
            props.style || {}
        ]
    }

    return (
        <TextInput
            {...inputProps}
        />
    )
}

const styles = StyleSheet.create({
    default: {
        fontFamily: 'NunitoSans_400Regular',
        fontSize: 16,
        width: 300,
        height: 40,
        paddingLeft: 10,
        paddingRight: 10,
        borderWidth: 1,
        borderColor: '#bbb',
        backgroundColor: 'white',
        borderRadius: 4,
        color: '#222'
    }
})