import { createStackNavigator } from '@react-navigation/stack'
import { FeedContextProvider } from '../context'
import FeedScreen from '../screen/FeedScreen'
import PhotoScreen from '../screen/PhotoScreen'

const FeedStack = createStackNavigator()

export default function() {
    return (
        <FeedContextProvider>
            <FeedStack.Navigator
                screenOptions={{
                    headerTitleAlign: 'center'
            }}>
                <FeedStack.Screen name="Feed" component={FeedScreen} />
                <FeedStack.Screen name="Photo" component={PhotoScreen} options={{
                    headerStyle: {
                        backgroundColor: '#000',
                    },
                    headerTintColor: '#fff'
                }}/>
            </FeedStack.Navigator>
        </FeedContextProvider>
    )
}