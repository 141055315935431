import { useContext } from 'react'
import {
    StyleSheet,
    TouchableOpacity,
    View
} from 'react-native'
import Constants from 'expo-constants'
import * as Linking from 'expo-linking'
import { AuthContext } from '../context'
import TextLabel from '../component/TextLabel'

function SettingItem(props) {

    if (props.onPress) {
        return (
            <TouchableOpacity onPress={props.onPress}>
                <View style={styles.item}>
                    {props.children}
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <View style={styles.item}>
            {props.children}
        </View>
    )

}

export default function SettingsScreen({ navigation }) {
    const { signOut } = useContext(AuthContext)
    const { version } = Constants.manifest

    return (
        <View>
            <SettingItem>
                <TextLabel style={styles.label}>Version: {version}</TextLabel>
            </SettingItem>

            <SettingItem onPress={() => Linking.openURL('https://commercescope.app/privacy-policy/')}>
                <TextLabel style={styles.label}>Privacy policy</TextLabel>
            </SettingItem>

            <SettingItem onPress={() => Linking.openURL('https://commercescope.app/terms-and-conditions/')}>
                <TextLabel style={styles.label}>Terms and conditions</TextLabel>
            </SettingItem>

            <SettingItem onPress={() => signOut()}>
                <TextLabel style={styles.label}>Logout</TextLabel>
            </SettingItem>
        </View>
    )
}

const styles = StyleSheet.create({
    item: {
        padding: 10,
        height: 50,
        justifyContent: 'center',
        borderBottomColor: 'rgba(0, 0, 0, .1)',
        borderBottomWidth: 1,
    },
    label: {
        fontSize: 18,
        marginBottom: 0,
        color: '#666',
        textAlign: 'left',
    }
})